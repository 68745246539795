<template>
<transition 
  enter-active-class="animate__animated animate__fadeInDown"
  leave-active-class="animate__animated animate__fadeOutUp"
  mode="out-in">
  <div class="modal main-modal">
    <div class="modal-container">
      <div class="modal-wrapper">
        <div class="swiper-modal swiper-carousel swiper-container swiper swiper-container-horizontal">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><KampanyaOlustur @clicked="slideToNext"/></div>
            <div class="swiper-slide"><KampanyaOlustur2 @clicked="slideToNext"/></div>
            <!-- <div class="swiper-slide"><Bilgiler @clicked="slideToNext" :formValue="formValue"/></div>
            <div class="swiper-slide"><Odeme @clicked="slideToNext"/></div> -->
            <div class="swiper-slide"><FormSuccess :successType="createCampaign" @clicked="closeModal;destroyCarousel"/></div>
          </div>
          
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <FrameFooter :backNav="backNav" @clicked="prevSlide; destroyModal"/>
      </div>

    </div>
  </div>
</transition>
</template>

<script>
import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import KampanyaOlustur from "@/components/KampanyaOlustur";
import KampanyaOlustur2 from "@/components/KampanyaOlustur2";
import Bilgiler from "@/components/Bilgiler";
import Odeme from "@/components/Odeme";
import FormSuccess from "@/components/FormSuccess";
import FrameFooter from "@/components/FrameFooter";

export default {
  name: "ModalKampanyaOlustur",
  components: {
    KampanyaOlustur, KampanyaOlustur2, Bilgiler, Odeme, FormSuccess, FrameFooter
  },
  data() {
    return {
      donationData: {},
      formValue: {},
      backNav: ["Ana Sayfa", "/"],
      formData: '',
      show: false,
      swiperModal: '',
      createCampaign: 'createCampaign',
    };
  },
  mounted() {
    this.getSwiper()
   // this.getDonationList();
   
    // this.$root.$emit('openLoginModalHome');
  },

  methods:{
    getSwiper(){
      setTimeout(() =>{
        this.swiperModal = new Swiper('.swiper-modal', {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          autoHeight: true,
          slideToClickedSlide:true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          grabCursor: false,
          allowTouchMove: false,
          simulateTouch: false,
        });
      }, 100);
    },
    getDonationList(){
      this.$api.donationList().then(response =>{
        this.donationData = response;
      });
    },
    footerLink(data){
      this.backNav = data;
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    slideToNext (value) {
      this.swiperModal.slideNext();
      
      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Kampanya Oluştur 1", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Kampanya Oluştur 2", ""]
      }else if (this.swiperModal.activeIndex == 3){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    prevSlide(){
      if(this.swiperModal.activeIndex > 0 ){
        this.swiperModal.slidePrev();
      }else{
        this.redirectHomePage()
      }

        if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Kampanya Oluştur 1", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Kampanya Oluştur 2", ""]
      }else if (this.swiperModal.activeIndex == 3){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    redirectHomePage(){
      // this.$router.push('/')
      window.location.href = '/'
    },
    destroyCarousel(){
      this.swiperModal = '';
      this.redirectHomePage()
    },
  }
};
</script>