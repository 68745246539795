<template>
<div class="modal main-modal">
  <div class="modal-container">
    <div class="modal-wrapper">
      <div class="swiper-modal swiper-carousel swiper-container swiper swiper-container-horizontal">
        <div class="swiper-wrapper">
          
          <div class="swiper-slide"><KartSec ref="refKartSec" :cardData="donationData" @clicked="slideToNext"/></div>
        <div class="swiper-slide"><HediyeBilgiler :url="imageUrl" :title="cardName" :desc="cardText" @clicked="slideToNext"/></div>
          <!-- <div class="swiper-slide"><KampanyaBagis :donationData="donationData" ref="progressBar" :progressBar="progressBar" /></div> -->
          <div class="swiper-slide"><Odeme @clicked="slideToNext" :donationType="giftCardDonate"/></div>
          <div class="swiper-slide"><FormSuccess  @clicked="closeModal;destroyCarousel"/></div>
          
          <!-- <div class="swiper-slide"><BagisSec /></div>
          
          <div class="swiper-slide"><KartBilgileri /></div> -->
          
        </div>
        
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
      <FrameFooter :backNav="backNav" @clicked="prevSlide; destroyModal"/>
    </div>

  </div>
</div>
</template>

<script>
import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import FrameFooter from "@/components/FrameFooter";
import BagisSec from "@/components/BagisSec";
import BirBagisYap from "@/components/BirBagisYap";
import KartSec from "@/components/KartSec";
import KampanyaBagis from "@/components/KampanyaBagis";
import Odeme from "@/components/Odeme";
import KartBilgileri from "@/components/KartBilgileri";
import Bilgiler from "@/components/Bilgiler";
import HediyeBilgiler from "@/components/HediyeKartiBilgiler";
import KampanyaOlustur from "@/components/KampanyaOlustur";
import FormSuccess from "@/components/FormSuccess";

export default {
  name: "HediyeKartiView",
  components: {
    BagisSec, BirBagisYap, KartSec, KampanyaBagis, Odeme, FrameFooter, KartBilgileri, Bilgiler, KampanyaOlustur,
    FormSuccess,HediyeBilgiler
  },
  data() {
    return {
      donationData: {},
      formValue: {},
      backNav: ["Ana Sayfa", "/"],
      formData: '',
      show: false,
      swiperModal: '',
      progressBar: '',
      giftCardDonate: "giftCardDonate",
      imageUrl : '',
      cardName: '',
      cardText: '',
    };
  },
  mounted() {
    this.getSwiper()
    this.getDonationList();
  },
  methods:{
    getSwiper(){
      setTimeout(() =>{
        this.swiperModal = new Swiper('.swiper-modal', {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          slideToClickedSlide:true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          grabCursor: false,
          allowTouchMove: false,
          simulateTouch: false,
          on: {
            init: () => this.onSlideInit(),
            slideChange: () => this.setCardImage()
          }
        });
      }, 500);
    },
    setCardImage(){
      this.imageUrl = this.$store.state.giftCardImageUrl;
      this.cardName = this.$store.state.giftCardName;
      this.cardText = this.$store.state.giftCardText;
    },
    onSlideInit(){
      this.$refs.refKartSec._swiperInit();
    },
    getDonationList(){
      this.$api.getGiftCardList().then(response =>{
        this.donationData = response;
      });
    },
    footerLink(data){
      this.backNav = data;
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    slideToNext (value) {
      this.swiperModal.slideNext();
      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Hediye Kartı Gönder", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    prevSlide(){
      if(this.swiperModal.activeIndex > 0 ){
        this.swiperModal.slidePrev();
      }else{
        this.redirectHomePage()
      }

      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Hediye Kartı Gönder", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    redirectHomePage(){
      // this.$router.push('/')
      window.location.href = '/'
    },
    destroyCarousel(){
      this.swiperModal = '';
      this.redirectHomePage()
    },
  }
};
</script>