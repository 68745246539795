<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
    <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>
    <modalVideo ref="modalVideo" :videoUrl="videoData"></modalVideo>
    <modalGallery ref="modalGallery"></modalGallery>
    <donorsModal ref="donorsModal" :data="donorList"></donorsModal>
    <ModalGlobal ref="ModalGlobal" :data="popupData"></ModalGlobal>

    <MainBanner 
      @clicked="showModal"
      :Title="this.pageContent.page && this.pageContent.page[2].title"
      :Desc="this.pageContent.page && this.pageContent.page[2].content"
      Image="/img/main-banner.jpg"
      TextPosition="bottom"
      Height="full"
      HasVideo="d-inline-block"
      videoData="iyIDtf50qbY"
    />

    <div class="main-continer">
      <section class="container bilim-seti-info section-high">
        <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between"
          v-if="this.pageContent.page">
          <div class="box box col-lg-5">
            <div v-html="this.pageContent.page[0].content"></div>
            <button class="button-rounded d-flex align-items-center justify-content-center mt-4 float-left blue"
              @click="showModalGallery">
                Bilim Setini incele
              </button>
          </div>
          <div class="box-delayed bilim-seti-img d-none d-lg-block">
            <img src="/svg/bilim-seti.svg" />
          </div>
        </div>
      </section>

      <section class="section-high bgPurpleLight h-full">
        <BagisSec
          class="container"
          :Title="bagisSecTitle"
          Desc=""
          @showBirBagisYap="showModal"
          @showKampanyaOlustur="modalKampanyaOlustur"
          @showHediyeKarti="modalHediyeKarti"
        />
      </section>

      <section class="toplanan-bagis section-padding container">
        <!-- <div class="row align-items-center justify-content-between" v-if="this.pageContent.page">
          <div class="col-12 col-md-5 text-center text-md-left" v-if="this.infographic.student">
            <h4 v-html="this.pageContent.page[1].title"></h4>
            <h2 v-html="this.infographic.student.toLocaleString('en')"></h2>
            <p v-html="this.pageContent.page[1].content"></p>
            <div class="logo-wraper">
              <a href="https://meb.gov.tr/" target="_blank"><img src="/svg/meb-logo.svg"></a>
              <a href="https://www.yga.org.tr/" target="_blank"><img src="/img/yga-logo.png"></a>
            </div>
          </div>

          <div class="col-6 d-none d-md-flex">
            <img src="/svg/toplanan-bagis.svg" class="toplanan-bagis-img" />
          </div>
        </div> -->

        <ToplananBagisInfographic
          :countTeacher="this.infographic.teacher"
          :countSchool="this.infographic.school"
          :countStudent="this.infographic.student"
          class="section-padding"
        />
      </section>

      <section class="section-padding">
        <MultipleCarousel
          v-if="teacherData.length > 0"
          :cardData="teacherData"
          nav="second"
          cardColor="green"
          @clicked="openVideo"
        />
      </section>

      <section class="section-padding destekciler">
        <div class="container">
          <ul class="nav nav-tab d-none d-md-flex">
            <li class="nav-item w-50 text-center position-relative">
              <a
                class="active"
                data-toggle="tab"
                href="#bireysel-destekciler"
                aria-controls="bireysel-destekciler"
                aria-selected="true"
                 @click="corpTab = false"
                >Bireysel Destekçiler</a
              >
            </li>
            <li class="nav-item w-50 text-center position-relative">
              <a
                class=""
                data-toggle="tab"
                href="#kurumsal-destekciler"
                aria-controls="kurumsal-destekciler"
                aria-selected="false"
                @click="reInıt()"
                >Kurumsal Destekçiler</a
              >
            </li>
          </ul>
          <ul class="nav nav-tab d-flex d-md-none">
            <li class="nav-item w-50 text-center position-relative">
              <a
                class="active"
                data-toggle="tab"
                href="#bireysel-destekciler"
                aria-controls="bireysel-destekciler"
                aria-selected="true"
                @click="corpTab = false"
                >Bireysel
              </a>
            </li>
            <li class="nav-item w-50 text-center position-relative">
              <a
                class=""
                data-toggle="tab"
                @click="reInıt() "
                href="#kurumsal-destekciler"
                aria-controls="kurumsal-destekciler"
                aria-selected="false"
                >Kurumsal
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="bireysel-destekciler"
              role="tabpanel"
            >
              <div v-if="induvidualSupporters.length > 0" class="image-out">
                <div
                  class="
                    swiper-carousel swiper-destekciler swiper-small
                    container
                    position-relative
                  "
                >
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide text-center"
                      v-for="(sup, index) in induvidualSupporters"
                      :key="index"
                    >
                      <!-- <img class="img-wrapper" :src="sup.image"> -->
                      <picture>
                        <source :srcset="sup.image" type="image/jpeg" />
                        <img :src="sup.image" alt="Görsel" />
                      </picture>
                      <h3 class="name">{{ sup.name_surname }}</h3>
                      <p class="title">{{ sup.title }}</p>
                    </div>
                  </div>

                  <!-- <div class="swiper-button swiper-button-destekciler swiper-button-next"></div>
          <div class="swiper-button swiper-button-destekciler swiper-button-prev"></div> -->
                  <!-- <div class="swiper-pagination"></div> -->
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="kurumsal-destekciler"
              role="tabpanel"
            >
              <div
                class="
                  swiper-carousel swiper-basinda-bilimseferberligi swiper-small
                  position-relative
                  pt-5
                "
                v-if="coorporateSupporters.length > 0"
              >
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide text-center"
                    v-for="(corp, index) in coorporateSupporters"
                    :key="index"
                  >
                    <div class="logo-wrapper">
                      <img :src="corp.image" />
                    </div>
                    <!-- <h3 class="name">{{ corp.name_surname }}</h3> -->
                  </div>
                </div>

                <!-- <div class="swiper-button swiper-button-destekciler swiper-button-next"></div>
              <div class="swiper-button swiper-button-destekciler swiper-button-prev"></div> -->
                <!-- <div class="swiper-pagination"></div> -->
              </div>
            </div>
          </div>

          <ButtonRounded
            class="mt-5 mx-auto"
            Text="Tüm Bağışçıları Gör"
            :isRouting="false"
            v-if="!corpTab"
            @click.native="showDonorsModal"
            color="blue"
          />
        </div>
      </section>

      <Quotes />

      <section class="section-high reports-out position-relative">
        <!-- <div class="faaliyet-raporu no-webp webp d-flex align-items-center"> -->
        <div class="faaliyet-raporu no-webp d-flex align-items-center">
          <div class="container d-flex d-md-block justify-content-center">
            <div>
              <div class="swiper-overflow-card text-center green">
                <h4 class="mt-3">{{ analysisReport.title }}</h4>
                
                <p class="mt-3">
                  {{ analysisReport.desc }}
                </p>

                <a
                  :href="analysisReport.link"
                  target="_blank"
                >
                  <div class="play-video">
                    <img src="/svg/icon-download.svg" class="mr-1" />
                    <span>Faaliyet Raporunu İndir</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <div class="spacer clearfix"></div> -->

      <FooterLanding />
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import ModalHediyeKarti from "@/components/ModalHediyeKarti";
import ModalVideo from "@/components/ModalVideo";
import ModalGallery from "@/components/ModalGallery";
import Quotes from "@/components/Quotes";
import FloatingCard from "@/components/FloatingCard";
import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
import ButtonRounded from "@/components/ButtonRounded";
import MultipleCarousel from "@/components/MultipleCarousel";
import donorsModal from "@/components/donorsModal";
import BagisSec from "@/components/BagisSec";
import ModalGlobal from "@/components/ModalGlobal";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

export default {
  name: "bagis",
  components: {
    FooterLanding,
    ModalBagisYap,
    ModalKampanyaOlustur,
    ModalHediyeKarti,
    ModalVideo,
    ModalGallery,
    Loading,
    BagisSec,
    MainBanner,
    Quotes,
    FloatingCard,
    ToplananBagisInfographic,
    ButtonRounded,
    MultipleCarousel,
    donorsModal,
    ModalGlobal,
  },
  data() {
    return {
      homeData: "",
      isLoading: false,
      fullPage: true,
      corpTab: false,
      listData: [],
      teacherData: {},
      studentData: {},
      induvidualSupporters: {},
      coorporateSupporters: {},
      mainSupporters: {},
      supportsVideo: {},
      newData: {},
      supps: {},
      videoUrl: "",
      videoData: "",
      bagisSecTitle: "Ben Nasıl Destek Olabilirim?",
      bagisSecDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. <br> Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      infographic: "",
      donorList: "",
      pageContent: [],
      analysisReport: '',
      popupData: '',
    };
  },
  created() {
    this.doAjax();
  },
  mounted() {
    if (document.location.search.length) {
      var search = location.search.substring(1);
      let logData = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      const userInfo = {
        success: true,
        token: logData.token,
        user: {
          id: logData.user_id,
          name: logData.user_name,
          email: logData.user_email,
          image: logData.user_image,
        },
      };

      //let userInfo = logData;
      localStorage.setItem("user-token", JSON.stringify(userInfo));
      localStorage.setItem("login-status", userInfo.success);
      this.$store.commit("_loginStatus", userInfo.success);
      this.$store.commit("_profileImage", userInfo.user.image);
      this.$cookies.set("userInfo", JSON.stringify(userInfo));
      this.$router.push("/");
    } else {
    }
    this._swiperInit();
    // this._fadeInBox();
    this.getStories();
    this.getSupporters();
    this.getInfographic();
    this.getDonors();
    this.getPageContent("homepage");
    this.getAnalysisReport();
    this.getPopupData();
    this.donateRoute();
  },

  methods: {
    redirectHomePage(){
      this.$router.push('/');
    },
    donateRoute(){
      if(this.$route.fullPath === '/bagis'){
         this.$refs.modalBagisYap.openModal();
         this.$refs.modalBagisYap.getSwiper();
      }
    },
    getPopupData(){
      //console.log("rota", this.$route.fullPath);
      this.$api.getPopup().then((response) => {
        if(response[0].status){
          this.showGlobalModal();
          this.popupData = response[0].content;
        }
      });
    },
    getAnalysisReport(){
      this.$api.analysisReport().then((response) => {
        this.analysisReport = response;
      });
    },
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
      });
    },
    getDonors() {
      this.$api.getDonors().then((response) => {
        this.donorList = response;
      });
    },
    showDonorsModal() {
      this.$refs.donorsModal.openModal();
    },
    getInfographic() {
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
    doAjax() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    getStories() {
      this.$api.getStories().then((response) => {
        this.teacherData = response.teacher;
        this.studentData = response.student;
      });
    },
    showModal() {
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    getSupporters() {
      this.$api.getSupporters().then((response) => {
        this.supps = response;
        this.induvidualSupporters = response.support_individual;
        this.coorporateSupporters = response.support_corporate;
        this.mainSupporters = response.advisory_board;

        setTimeout(() => {
          this._swiperInit();
        }, 300);
      });
    },
    modalKampanyaOlustur() {
      this.$refs.modalKampanyaOlustur.openModal();
      this.$refs.modalKampanyaOlustur.getSwiper();
    },
    modalHediyeKarti() {
      this.$refs.modalHediyeKarti.openModal();
      this.$refs.modalHediyeKarti.getSwiper();
    },
    openVideo(data) {
      this.showModalVideo(data);
    },
    showModalVideo(data) {
      this.videoData = data;
      this.$refs.modalVideo.openModal();
    },
    showModalGallery() {
      this.$refs.modalGallery.openModal();
    },
    showSignupModal() {
      this.$refs.modalSignup.openModal();
      this.$refs.modalSignup.getSwiper();
    },
    showGlobalModal() {
      this.$refs.ModalGlobal.openModal();
    },
    reInıt() {
      this.corpTab = true;
      setTimeout(() => {
        const swiperBasinda = new Swiper(".swiper-basinda-bilimseferberligi", {
          loop: false,
          //slidesPerView: 1,
          spaceBetween: 30,
          breakpoints: {
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          },
          centeredSlides: false,
          pagination: {
            el: ".swiper-pagination",
          },
        });
      }, 300);
    },
    _swiperInit() {
      const swiperBireyselDestekciler = new Swiper(
        "#bireysel-destekciler .swiper-destekciler",
        {
          spaceBetween: 30,
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            567: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          },
        }
      );

      const swiperKurumsalDestekciler = new Swiper(
        "#kurumsal-destekciler .swiper-destekciler",
        {
          spaceBetween: 30,
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            567: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 4,
            },
          },
        }
      );
    },
    _fadeInBox() {
      const boxes = gsap.utils.toArray(".box");
      const boxesDelayed = gsap.utils.toArray(".box-delayed");

      boxes.forEach((box, i) => {
        const anim = gsap.fromTo(
          box,
          { autoAlpha: 0, y: 50 },
          { duration: 1, autoAlpha: 1, y: 0 }
        );
        ScrollTrigger.create({
          trigger: box,
          animation: anim,
          toggleActions: "play none complete none",
        });
      });

      boxesDelayed.forEach((box, i) => {
        const anim = gsap.fromTo(
          box,
          { autoAlpha: 0, y: 50 },
          { duration: 1, delay: 0.5, autoAlpha: 1, y: 0 }
        );
        ScrollTrigger.create({
          trigger: box,
          animation: anim,
          toggleActions: "play none complete none",
        });
      });
    },
  },
};
</script>

<style lang="scss">
 .logo-wraper img {
    margin: 1rem 0 0 1rem;
}
@media (max-width: 576px){
  .gray-section img {
      padding: 1rem;
  }
}

.fixed-bagis-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 9;
}
.h-full {
  @media (min-width: 1000px) and (max-width: 1440px) {
    height: 100vh !important;
  }
}
.main-continer {
  padding-top: 5rem;
  color: var(--purpleDark);
  overflow-x: hidden;
  position: relative;
  background: #fff;

  h2 {
    font-size: 3.85rem;
    font-weight: 600;
  }

  h3 {
    font-size: 3.5rem;
    font-weight: 600;
  }

  ul {
    list-style: disc;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  .footnote {
    font-size: 0.8rem;
    font-weight: 400 !important;
    span {
      font-weight: 600;
    }
  }

  .bilim-seti-info {
    padding-bottom: 5rem;
    p {
      margin: 1rem 0;
    }

    .bilim-seti-img {
      img {
        // min-width: 100%;
        // margin-top: -7rem;
        // margin-left: 0rem;
        margin-left: 0;
        width: 90%;
        float: right;
      }
    }

    .footnote {
      margin: 3rem 0;
    }
  }

  // TOPLANAN-BAGIS
  .toplanan-bagis {
    img.toplanan-bagis-img {
      margin-top: -25%;
      position: relative;
      z-index: 1;
    }
  }

  // DESTEKÇILER
  .destekciler {
    .nav-tab .nav-item a {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  .faaliyet-raporu {
    width: 100vw;
    height: 100%;
    &.no-webp {
      background: url("/img/faaliyet-raporu.jpg") no-repeat center;
      background-size: cover;
    }
    &.webp {
      background: url("/img/faaliyet-raporu.webp") no-repeat center;
      background-size: cover;
    }
    .swiper-overflow-card {
      float: right;
      margin-right: 4rem;
      text-align: left !important;
    }
  }

  @media (max-width: 810px) {
    .bilim-seti-info {
      .bilim-seti-img img {
        width: 100%;
      }
    }
  }
  @media (max-width: 576px) {
    padding-top: 4rem;

    h2 {
      font-size: 2.45rem;
      margin: 1rem 0;
    }
    .bilim-seti-info {
      text-align: center;
      .bilim-seti-img img {
        min-width: unset;
        margin-top: 2rem;
        margin-bottom: 1rem;
        width: 100%;
        padding: 0 2rem;
      }
      ul {
        text-align: left;
      }
      .footnote {
        margin: 1.5rem 0;
        font-size: 0.6rem;
        font-weight: 300;
      }
    }
    .container {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .toplanan-bagis-img {
      margin-top: 2rem !important;
      margin-left: 0 !important;
    }
    .toplanan-bagis-infographic {
      img {
        max-height: 120px;
        height: 120px;
        max-width: 130px;
        object-fit: contain;
      }
    }
    .swiper-overflow-card {
      margin-right: 0px !important;
    }
    .faaliyet-raporu {
      height: 75vh;
      .swiper-overflow-card {
        margin-right: 0px !important;
      }
    }
    .button-rounded {
      width: 100%;
      &.middle {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .bilim-seti-info {
      .bilim-seti-img {
        text-align: center;
        img {
          margin-top: 3rem;
          max-width: 75%;
          min-width: 50%;
        }
      }
    }
    .bagis-card {
      h3 {
        font-size: 1.2rem;
      }
    }
    .reports-out {
      height: 65vh;
    }
    .toplanan-bagis-infographic {
      img {
        max-height: 135px;
        height: 135px;
      }
    }
    .toplanan-bagis-img {
      margin-top: 0px !important;
      margin-left: 5rem;
    }
  }
  @media (min-width: 576px) and (max-width: 768px) {
    .subscribe {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
@media (max-width: 567px) {
  .reports-out {
    margin-top: 200px;
    .swiper-overflow-card {
      position: absolute;
      top: -150px;
      left: calc(50% - 173px);
      width: 346px;
    }
  }
  .destekciler {
    .nav-tab {
      .nav-item {
        a {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .quotes {
    .quotes-profile {
      h3 {
        font-size: 22px;
      }
    }
  }
}
</style>
