<template>
<transition 
  enter-active-class="animate__animated animate__fadeInDown"
  leave-active-class="animate__animated animate__fadeOutUp"
  mode="out-in">
>
  <div class="modal main-modal">
    <div class="modal-container">
      <div class="modal-wrapper">
        <div class="swiper-modal swiper-carousel swiper-container swiper swiper-container-horizontal">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><BirBagisYap :cities="allCities"  @clicked="slideToNext" :donationData="donationData"/></div>
            <div class="swiper-slide"><Bilgiler @clicked="slideToNext" :formValue="formValue" :donationType="standartDonate"/></div>
            <div class="swiper-slide"><Odeme :donationType="standartDonate" @clicked="slideToNext"/></div>
            <div class="swiper-slide"><FormSuccess  @clicked="closeModal;destroyCarousel"/></div>
          </div>
          
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
        <FrameFooter :backNav="backNav" @clicked="prevSlide; destroyModal"/>
      </div>

    </div>
  </div>
</transition>
</template>

<script>
import { Swiper, Scrollbar, EffectCoverflow, SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, EffectFade } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar, EffectCoverflow, Mousewheel, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

import FrameFooter from "@/components/FrameFooter";
import BagisSec from "@/components/BagisSec";
import BirBagisYap from "@/components/BirBagisYap";
import KartSec from "@/components/KartSec";
import KampanyaBagis from "@/components/KampanyaBagis";
import Odeme from "@/components/Odeme";
import KartBilgileri from "@/components/KartBilgileri";
import Bilgiler from "@/components/Bilgiler";
import KampanyaOlustur from "@/components/KampanyaOlustur";
import FormSuccess from "@/components/FormSuccess";

export default {
  name: "BagisYapView",
  components: {
    BagisSec, BirBagisYap, KartSec, KampanyaBagis, Odeme, FrameFooter, KartBilgileri, Bilgiler, KampanyaOlustur,
    FormSuccess
  },
  data() {
    return {
      donationData: {},
      formValue: {},
      backNav: ["Ana Sayfa", "/"],
      formData: '',
      allCities:{},
      standartDonate: "standartDonate",
      show: false,
      swiperModal: '',
    };
  },
  mounted() {
    this.getSwiper();
    
    this.getDonationList();
    this.getCities();
  },
  methods:{
    getSwiper(){
      setTimeout(() =>{
        this.swiperModal = new Swiper('.swiper-modal', {
          pagination: {
            el: ".swiper-pagination",
            type: "progressbar",
          },
          loop: false,
          slideToClickedSlide:true,
          paginationClickable: false,
          mousewheelControl: 1,
          parallax: false,
          speed: 0,
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: { crossFade: true },
          allowTouchMove: false,
          grabCursor: false,
          simulateTouch: false,
        });
      }, 100);
    },
    getDonationList(){
      let params = {};
      params["type"] = "normal";
      this.$api.donationList(params).then(response =>{
        this.donationData = response;
      });
    },
    footerLink(data){
      this.backNav = data;
    },
    getCities(){
        this.$api.getAllCities().then(response =>{
          this.allCities = response;
        });
    },
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    slideToNext (value) {
      this.swiperModal.slideNext();
      
      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Bağış Yap", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    prevSlide(){
      if(this.swiperModal.activeIndex > 0 ){
        this.swiperModal.slidePrev();
      }else{
        // this.closeModal();
        this.redirectHomePage()
      }

      if (this.swiperModal.activeIndex  == 0){
        this.backNav = ["Ana Sayfa", ""]
      }else if (this.swiperModal.activeIndex  == 1){
        this.backNav = ["Bağış Yap", ""]
      }else if (this.swiperModal.activeIndex == 2){
        this.backNav = ["Bilgileriniz", ""]
      }
    },
    redirectHomePage(){
      // this.$router.push('/')
      window.location.href = '/'
    },
    destroyCarousel(){
      this.swiperModal = '';
      this.redirectHomePage()
    },
  }
};
</script>